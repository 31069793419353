import React from 'react';
import * as SC from './styled';

type CardProps = React.ComponentProps<typeof SC.OuterRoot>;

const Card = React.forwardRef(({ children, ...props }: CardProps, ref) => (
  <SC.OuterRoot ref={ref as React.RefObject<HTMLDivElement>} {...props}>
    <SC.Root>{children}</SC.Root>
  </SC.OuterRoot>
));

Card.displayName = 'CardWithGradientBorder';

export { Card };

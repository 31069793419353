'use client';

import styled from 'styled-components';

export const SectionTitle = styled.h2`
  margin: 0 0 25px 0;
  white-space: pre-line;
  font-style: normal;
  color: var(--primary-text);
  font-size: 64px;
  font-weight: 600;
  line-height: 76px;
  letter-spacing: -1.7px;

  span {
    font-weight: 100;
  }

  @media (max-width: 768px) {
    text-align: center;
    font-size: 32px;
    line-height: 42px;
  }
`;

"use client";

import React from "react";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/image";
import arrow from "@/public/staticfiles/icons/arrow-right.svg";
import minion from "@/public/staticfiles/reviews/minion.webp";
import roflan from "@/public/staticfiles/reviews/roflan.webp";
import rat from "@/public/staticfiles/reviews/rat.webp";
import tryapka from "@/public/staticfiles/reviews/tryapka.webp";
import { Container } from "@/shared/ui/container";
import { SectionTitle } from "@/shared/ui/section-title";
import * as SC from "./styled";
import { useMediaQuery } from "@/shared/hooks";

function Reviews() {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const reviewsList = [
    {
      img: minion,
      username: "TheRAT",
      review:
        "Finally found an app where I can manage all my 6 accounts!!! Plus a nice bonus – I can easily access any blocked chats . Fels like ive unlocked a hidden level in messaging apps\n",
    },
    {
      img: roflan,
      username: "Joe_d",
      review:
        "Great that there's already a translator in the app – no need for extras to chat with foreigners. But what's even better is a builtin AI.  It's good to have a companion that helps with sorting out my owm thoughts \uD83D\uDE04",
    },
    {
      img: rat,
      username: "AlesiaVoice",
      review:
        "don't mean to say I'm paranoid or have anything to hide but the sense of security in using secret chatrooms and the 'double bottom' feature feels good to me ",
    },
    {
      img: tryapka,
      username: "SandyK",
      review:
        "Im really into sending video messages to my friends, and it was such a surprise to find out that Nicegram lets me send not only freshly shot ones but also share any pre-recorded ones in the same way. Love \uD83E\uDEF6\n",
    },
  ];

  return (
    <SC.Wrapper>
      <SC.Title>
        <SectionTitle>Hear it from our Community!</SectionTitle>
        <SC.SliderButtonsWrapper>
          <button type="button" id="reviews_btn-prev">
            <Image width={18} height={18} src={arrow} alt="arrow" />
          </button>
          <button type="button" id="reviews_btn-next">
            <Image width={18} height={18} src={arrow} alt="arrow" />
          </button>
        </SC.SliderButtonsWrapper>
      </SC.Title>
      <SC.SliderWrapper>
        <Swiper
          slidesPerView={isMobile ? 1 : 3}
          spaceBetween={16}
          pagination={{
            // uncomment when added more than 6 reviews
            // dynamicBullets: true,
            clickable: true,
          }}
          navigation={{
            enabled: true,
            nextEl: "#reviews_btn-next",
            prevEl: "#reviews_btn-prev",
            disabledClass: "disabled",
          }}
          allowTouchMove={isMobile}
          modules={[Navigation, Pagination]}
          className="slick-slider"
        >
          {reviewsList.map(({ review, username, img }) => (
            <SwiperSlide key={`${username}`}>
              <div style={{ height: "100%" }}>
                <SC.Review>
                  <SC.ReviewTitle>
                    <SC.ReviewTitleImageWrapper>
                      <Image width={48} height={48} src={img} alt="username" />
                    </SC.ReviewTitleImageWrapper>
                    {username}
                  </SC.ReviewTitle>
                  <SC.ReviewText>{review}</SC.ReviewText>
                </SC.Review>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </SC.SliderWrapper>
    </SC.Wrapper>
  );
}

export { Reviews };

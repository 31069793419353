import styled from 'styled-components';
import { Container } from '@/shared/ui/container';

export const AppstoreButton = styled.a`
  text-decoration: none;
  background: #ffffff;
  box-shadow: 0 0 32px 0 #ffffffcc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
export const ButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  .btn,
  a {
    text-transform: capitalize;
    flex: 1 1 250px;
    max-width: 300px;
    width: 100%;
    height: 56px;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    justify-content: center;
    gap: 15px;
  }
`;
export const Content = styled.div`
  flex: 1;
`;
export const ImageWrapper = styled.div`
  flex: 0 1 460px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1024px) {
    flex: 0 1 400px;
  }

  @media (max-width: 900px) {
    flex: 0 1 350px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
  z-index: 2;

  h1 {
    margin: 0;
    font-size: 64px;
    font-weight: 700;
    line-height: 76px;
    letter-spacing: -1.7px;
    color: var(--primary-text);
  }

  p {
    color: var(--primary-text);
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin: 16px 0 0 0;
  }

  @media (max-width: 1024px) {
    gap: 30px;

    h1 {
      font-size: 32px;
      line-height: 40px;
    }

    p {
      font-size: 22px;
      line-height: 26px;
    }
  }

  @media (max-width: 768px) {
    text-align: center;

    h1 {
      font-size: 32px;
      line-height: 40px;
      margin: 0 auto;
      max-width: 500px;
    }

    p {
      margin: 16px auto 0 auto;
      max-width: 500px;
      font-size: 18px;
      line-height: 22px;
    }
  }
`;

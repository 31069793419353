"use client";

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;

  .btn {
    background: white;
  }

  @media (max-width: 430px) {
    flex-wrap: wrap;
  }
`;

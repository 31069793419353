'use client';

import React from 'react';
import { useAnalyticsContext } from '@/processes/contexts/analytics';
import { X_LEAD_EVENT_ID, X_PAGE_VIEW_EVENT_ID } from '@/processes/contexts/analytics/constants';
import { CtaButtonsModel } from '@/entities/cta-buttons';
import { Button } from '@/shared/ui/button';
import { useOneLinkContext } from '@/processes/contexts/one-link';

import { Wrapper } from './styled';

function DownloadHeader() {
  const { sendSafeMixpanelEvent, sendSafeFbqEvent, sendSafeXEvent, sendSafeYMEvent } = useAnalyticsContext();
  const { href } = useOneLinkContext();

  React.useEffect(() => {
    sendSafeXEvent(X_PAGE_VIEW_EVENT_ID);
  }, []);

  return (
    <Wrapper>
      <h1>Nicegram: Unleash the Full Potential of Your Telegram Account</h1>
      <p>Experience Revolutionary Messaging - Multiple Accounts, AI Integration, and Unmatched Privacy</p>
      <Button
        id={CtaButtonsModel.constants.HEADER_CTA_BUTTON_ID}
        as="a"
        fullWidth
        href={href}
        rel="noreferrer"
        target="_blank"
        onClick={() => {
          sendSafeFbqEvent('Lead');
          sendSafeXEvent(X_LEAD_EVENT_ID);
          sendSafeFbqEvent('Lead');
          sendSafeYMEvent('app_store_click');
          sendSafeMixpanelEvent('track', 'app_store_click');
        }}
        gradient
        label={'Download'}
      />
    </Wrapper>
  );
}

export { DownloadHeader };

'use client';

import React from 'react';
import Image from 'next/image';
import appstore from '@/public/staticfiles/appstore-black.svg';
import gplay from '@/public/staticfiles/gplay-black.svg';
import headerImage from '@/public/staticfiles/download/header-image.png';
import { useAnalyticsContext } from '@/processes/contexts/analytics';
import { X_LEAD_EVENT_ID, X_PAGE_VIEW_EVENT_ID } from '@/processes/contexts/analytics/constants';
import { useDeviceInfoContext } from '@/processes/contexts/device-info';
import { CtaButtonsModel } from '@/entities/cta-buttons';
import { useMediaQuery } from '@/shared/hooks';
import { APPSTORE_LINK, GPLAY_LINK } from '@/shared/constants';
import { scrollToId } from '@/shared/lib/interactions';
import { Button } from '@/shared/ui/button';
import * as SC from './styled';

function DownloadHeader() {
  const { sendSafeMixpanelEvent, sendSafeFbqEvent, sendSafeXEvent, sendSafeYMEvent, sendSafeGtagEvent } =
    useAnalyticsContext();
  const { isMobile: isMobileAgent, isIOS } = useDeviceInfoContext();
  const isMobileView = useMediaQuery('(max-width:768px)');

  const isMobile = isMobileAgent || isMobileView;

  React.useEffect(() => {
    sendSafeXEvent(X_PAGE_VIEW_EVENT_ID);
  }, []);

  return (
    <SC.Wrapper>
      <SC.Content>
        <h1>Nicegram: Unleash the Full Potential of Your Telegram Account</h1>
        <p>Experience Revolutionary Messaging - Multiple Accounts, AI Integration, and Unmatched Privacy</p>
        <SC.ButtonsWrapper>
          {isMobile && (
            <SC.AppstoreButton
              onClick={() => {
                sendSafeFbqEvent('Lead');
                sendSafeXEvent(X_LEAD_EVENT_ID);
                sendSafeYMEvent('app_store_click');
                sendSafeGtagEvent('app_store_click');
                sendSafeMixpanelEvent('track', 'app_store_click');
              }}
              href={isIOS ? APPSTORE_LINK : GPLAY_LINK}
              target="_blank"
              rel="noreferrer nofollow"
            >
              <Image src={isIOS ? appstore : gplay} alt="" />
            </SC.AppstoreButton>
          )}
          <Button
            id={CtaButtonsModel.constants.HEADER_CTA_BUTTON_ID}
            variant="gradient"
            label="Download for other devices"
            onClick={() => {
              scrollToId('download-app-block');
              sendSafeFbqEvent('Lead');
              sendSafeXEvent(X_LEAD_EVENT_ID);
              sendSafeYMEvent('app_store_click');
              sendSafeGtagEvent('app_store_click');
              sendSafeMixpanelEvent('track', 'app_store_click');
            }}
          />
        </SC.ButtonsWrapper>
      </SC.Content>
      <SC.ImageWrapper>
        <Image src={headerImage} width={456} height={456} alt="" />
      </SC.ImageWrapper>
    </SC.Wrapper>
  );
}

export { DownloadHeader };

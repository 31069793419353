'use client';

import React from 'react';
import Image from 'next/image';
import image from '@/public/staticfiles/download/header-image.png';
import android from '@/public/staticfiles/download/android-jb-apk.svg';
import iosJB from '@/public/staticfiles/download/ios-jb.svg';
import { useDeviceInfoContext } from '@/processes/contexts/device-info';
import { buildTelegramLinkWithUserDeviceType } from '@/shared/lib/link-formatters';
import { DownloadAppButtons } from '@/entities/download-app-buttons';
import { Button } from '@/shared/ui/button';
import * as SC from './styled';

function DownloadAppBlock() {
  const { type } = useDeviceInfoContext();

  return (
    <SC.Wrapper id="download-app-block">
      <div>
        <SC.BlockTitle>Join the Messaging Revolution Now!</SC.BlockTitle>
        <DownloadAppButtons />
        <SC.JBAppInstall>
          <Button
            as="a"
            target="_blank"
            rel="noreferrer nofollow"
            href={buildTelegramLinkWithUserDeviceType('https://t.me/nicegram_ipa', type)}
            label={<Image src={iosJB} alt="" />}
          />
          <Button
            as="a"
            target="_blank"
            rel="noreferrer nofollow"
            href={buildTelegramLinkWithUserDeviceType('https://t.me/nicegram_apk', type)}
            label={<Image src={android} alt="" />}
          />
        </SC.JBAppInstall>
      </div>
      <SC.ImagesWrapper>
        <Image width={600} height={600} src={image} alt="" />
      </SC.ImagesWrapper>
    </SC.Wrapper>
  );
}

export { DownloadAppBlock };

'use client';

import styled from 'styled-components';
import { Container } from '@/shared/ui/container';

export const QuestionItemText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.2px;
  color: var(--secondary-text);
`;
export const QuestionItemTitle = styled.div`
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
`;
export const QuestionItem = styled.div`
  padding: 35px 0;
  border-bottom: 1px solid #38383a;

  &:last-child {
    border-bottom: none;
  }
`;
export const QuestionsList = styled.div`
  flex: 1;
`;
export const BlockTitle = styled.div`
  flex: 0 0 300px;
  font-size: 64px;
  font-weight: 700;
  line-height: 76px;
  letter-spacing: -1.7px;
`;

export const Wrapper = styled(Container)`
  display: flex;
  gap: 50px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 25px;

    ${BlockTitle} {
      text-align: center;
      flex: 1;
      font-size: 32px;
      line-height: 40px;
    }

    ${QuestionItem} {
      padding: 25px 0;
    }
  }
`;

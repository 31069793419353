"use client";

import styled from "styled-components";
import { Container } from "@/shared/ui/container";

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  text-align: center;

  h1 {
    font-size: 80px;
    line-height: 90px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -1.9px;
    margin: 0;
  }

  p {
    color: var(--primary-text);
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin: 0 auto;
    max-width: 740px;
  }

  .btn {
    max-width: 300px;
    margin: 0 auto;
  }

  @media (max-width: 1440px) {
    h1 {
      font-size: 72px;
      line-height: 84px;
    }
  }

  @media (max-width: 1024px) {
    gap: 30px;

    h1 {
      font-size: 52px;
      line-height: 64px;
      margin: 40px 0 0 0;
    }

    p {
      font-size: 22px;
      line-height: 26px;
    }
  }

  @media (max-width: 768px) {
    gap: 25px;

    h1 {
      font-size: 32px;
      line-height: 40px;
      margin: 40px 0 0 0;
    }

    p {
      max-width: 500px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  @media (max-width: 430px) {
    .btn {
      margin-top: 25px;
    }
  }
`;

'use client';

import styled from 'styled-components';
import { CardWithGradientBorder } from '@/shared/ui/card';

export const SliderButtonsWrapper = styled.div`
  width: fit-content;
  display: flex;
  grid-gap: 10px;
  align-items: flex-end;
  justify-content: flex-end;

  > button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 100vmax;
    background: rgba(255, 255, 255, 0.1);
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.05));
    border: none;

    &.disabled {
      opacity: 0.5;
    }

    img {
      filter: invert(${(props) => (props.theme.name === 'light' ? 1 : 0)});
    }

    &:first-child {
      img {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    position: absolute;
    bottom: 0;
    justify-content: center;
    left: 0;
  }
`;
export const SliderWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;

  .swiper-slide {
    height: auto;
  }

  .slick-slider {
    overflow: visible;
    padding-top: 50px;
    position: unset;
  }

  @media (max-width: 768px) {
    &:before,
    &:after {
      display: none;
    }
    .slick-slider {
      padding-top: 25px;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  h2 {
    margin-bottom: 0;
  }

  > div {
    button {
      margin-top: 25px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;

    > div > button {
      margin: 15px auto 0 auto;
    }
  }
`;

export const ReviewTitleImageWrapper = styled.div`
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 100vmax;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const ReviewTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: var(--primary-text);
  display: flex;
  align-items: center;
  grid-gap: 15px;
  margin-bottom: 15px;
  font-style: normal;
`;
export const ReviewText = styled.div`
  color: var(--primary-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.1px;
`;
export const Review = styled(CardWithGradientBorder)`
  height: 100%;

  > div {
    padding: 25px;
  }

  @media (max-width: 430px) {
    > div {
      padding: 25px 20px;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 4;

  .swiper-pagination {
    display: none;
  }

  .slick-slider {
    overflow: visible;
    padding: 50px 0 0 0;
    position: unset;
    max-width: 1240px;
  }

  @media (max-width: 1280px) {
    .slick-slider {
      max-width: 1140px;
    }
  }

  @media (max-width: 1140px) {
    .slick-slider {
      padding: 50px 16px 0 16px;
    }
  }
  @media (max-width: 768px) {
    padding: 50px 0;

    .swiper-pagination {
      display: block;
      bottom: -50px;
      z-index: 3;
      width: fit-content;
      left: 50%;
      transform: translateX(-50%);
      .swiper-pagination-bullet {
        background: rgba(255, 255, 255, 0.8);
      }
      .swiper-pagination-bullet-active {
        background: white;
      }
    }
  }
  @media (max-width: 430px) {
    .slick-slider {
      padding: 20px 16px 0 16px;
    }
  }
`;

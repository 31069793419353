import styled from "styled-components";
import arrow from "@/public/staticfiles/download/arrow-left.svg";
import { Container } from "@/shared/ui/container";
import { Wrapper as DownloadAppButtons } from "@/entities/download-app-buttons";

export const JBAppInstall = styled.div`
  width: fit-content;
  display: grid;
  gap: 10px 25px;
  grid-template-columns: 160px 1fr;
  padding-top: 35px;
  margin-top: 35px;
  border-top: 1px solid #38383a;

  a:not(.btn) {
    width: fit-content;
    grid-column: 2;
    grid-row: 1;
    align-items: center;
    display: flex;
    color: #0b84ff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    text-decoration-line: underline;
  }

  a.btn {
    max-width: 160px;
    grid-column: 1;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background: none;
  }

  @media (max-width: 768px) {
    margin: 25px auto 0 auto;
  }
  @media (max-width: 430px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    margin-top: 25px;
  }
`;

export const ImagesWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
  }

  > img:first-child {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    > img:first-child {
      max-height: 400px;
    }
  }
`;

export const BlockTitle = styled.div`
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1.4px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.4px;
    margin-bottom: 35px;
  }
`;

export const Wrapper = styled(Container)`
  display: flex;
  align-items: center;

  > * {
    flex: 1;
    width: 100%;
  }

  ${DownloadAppButtons} {
    position: relative;
    width: fit-content;

    &:after {
      content: url(${arrow.src});
      position: absolute;
      right: 0;
      transform: translateX(140%);
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;

    > div:first-child {
      text-align: center;

      ${DownloadAppButtons} {
        width: auto;
        justify-content: center;

        &:after {
          display: none;
        }
      }
    }
  }
`;

"use client";

import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding-bottom: 100px;

  @media (max-width: 768px) {
    gap: 50px;

    > * {
      padding-top: 0;
    }
  }
`;

import React from 'react';
import Image from 'next/image';
import appleIcon from '@/public/staticfiles/appstore-black.svg';
import googlePlayIcon from '@/public/staticfiles/gplay-black.svg';
import { useAnalyticsContext } from '@/processes/contexts/analytics';
import { X_LEAD_EVENT_ID } from '@/processes/contexts/analytics/constants';
import { APPSTORE_LINK, GPLAY_LINK } from '@/shared/constants';
import { Button } from '@/shared/ui/button';
import { Wrapper } from './styled';

type DownloadAppButtonsProps = {
  appstoreLink?: string;
  gplayLink?: string;
};

function DownloadAppButtons({
  appstoreLink = APPSTORE_LINK,
  gplayLink = GPLAY_LINK,
  ...props
}: DownloadAppButtonsProps) {
  const { sendSafeMixpanelEvent, sendSafeFbqEvent, sendSafeXEvent, sendSafeYMEvent, sendSafeGtagEvent } =
    useAnalyticsContext();

  return (
    <Wrapper {...props}>
      <Button
        disableAnimation
        as="a"
        target="_blank"
        rel="noreferrer"
        href={appstoreLink}
        onClick={() => {
          sendSafeFbqEvent('Lead');
          sendSafeXEvent(X_LEAD_EVENT_ID);
          sendSafeMixpanelEvent('track', 'app_store_click');
          sendSafeGtagEvent('app_store_click');
          sendSafeYMEvent('app_store_click');
        }}
        label={<Image width={100} height={26} src={appleIcon} alt="apple logo" />}
      />
      <Button
        disableAnimation
        as="a"
        target="_blank"
        rel="noreferrer"
        href={gplayLink}
        onClick={() => {
          sendSafeFbqEvent('Lead');
          sendSafeXEvent(X_LEAD_EVENT_ID);
          sendSafeYMEvent('app_store_click');
          sendSafeGtagEvent('app_store_click');
          sendSafeMixpanelEvent('track', 'app_store_click');
        }}
        label={<Image width={110} height={26} src={googlePlayIcon} alt="gplay logo" />}
      />{' '}
    </Wrapper>
  );
}

export { DownloadAppButtons, type DownloadAppButtonsProps };

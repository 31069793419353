'use client';

import React from 'react';
import Link from 'next/link';
import { useAnalyticsContext } from '@/processes/contexts/analytics';
import { X_LEAD_EVENT_ID } from '@/processes/contexts/analytics/constants';
import { useOneLinkContext } from '@/processes/contexts/one-link';

import * as SC from './styled';

function FaqBlock() {
  const { sendSafeMixpanelEvent, sendSafeFbqEvent, sendSafeXEvent, sendSafeYMEvent, sendSafeGtagEvent } =
    useAnalyticsContext();
  const { href } = useOneLinkContext();

  return (
    <SC.Wrapper>
      <SC.BlockTitle>FAQ</SC.BlockTitle>
      <SC.QuestionsList>
        <SC.QuestionItem>
          <SC.QuestionItemTitle>How do I get Nicegram for macOS?</SC.QuestionItemTitle>
          <SC.QuestionItemText>
            Nicegram is available for macOS Apple M1 and latest -{'>'}{' '}
            <Link
              onClick={() => {
                sendSafeFbqEvent('Lead');
                sendSafeXEvent(X_LEAD_EVENT_ID);
                sendSafeYMEvent('app_store_click');
                sendSafeGtagEvent('app_store_click');
                sendSafeMixpanelEvent('track', 'app_store_click');
              }}
              target="_blank"
              rel="noreferrer"
              href={href}
            >
              Download
            </Link>
          </SC.QuestionItemText>
        </SC.QuestionItem>{' '}
        <SC.QuestionItem>
          <SC.QuestionItemTitle>How do I get Nicegram for Android?</SC.QuestionItemTitle>
          <SC.QuestionItemText>
            Nicegram was generally available for iOS, but in 2021 we&apos;re introducting Nicegram for Android. It may
            lack of some features for a while, but you will definitely love it!{' '}
            <Link
              onClick={() => {
                sendSafeFbqEvent('Lead');
                sendSafeXEvent(X_LEAD_EVENT_ID);
                sendSafeYMEvent('app_store_click');
                sendSafeGtagEvent('app_store_click');
                sendSafeMixpanelEvent('track', 'app_store_click');
              }}
              target="_blank"
              rel="noreferrer"
              href="https://nicegramap.onelink.me/gn7C/in9viwzk"
            >
              Download in the Play Store
            </Link>
          </SC.QuestionItemText>
        </SC.QuestionItem>{' '}
        <SC.QuestionItem>
          <SC.QuestionItemTitle>Why can&apos;t I join the TestFlight beta?</SC.QuestionItemTitle>
          <SC.QuestionItemText>
            TestFlight betas are limited to 10,000 testers. It is very likely that all the beta slots are full. If no
            new slots have opened up after a few days, politely state in the Nicegram Chat that the TestFlight beta is
            full and you would like to join. The developer will clear out beta testers who have not updated the app
            recently so that new testers can join.
          </SC.QuestionItemText>
        </SC.QuestionItem>{' '}
        <SC.QuestionItem>
          <SC.QuestionItemTitle>Why was I removed from the TestFlight beta?</SC.QuestionItemTitle>
          <SC.QuestionItemText>
            Beta slots for Nicegram are in high demand. If you have not updated your beta copy of Nicegram recently, the
            developer will remove you so that other testers may join the beta.
          </SC.QuestionItemText>
        </SC.QuestionItem>{' '}
        <SC.QuestionItem>
          <SC.QuestionItemTitle>
            Why hasn&apos;t Nicegram been updated with the newest features from Telegram?
          </SC.QuestionItemTitle>
          <SC.QuestionItemText>
            Nicegram is mostly a copy of the official Telegram app. However, the developers making the official app
            frequently change their version in ways that break Nicegram&apos;s nice features. We don&apos;t think
            they&apos;re doing it on purpose; writing apps is hard. Our developer does his best to make sure that new
            changes to the official Telegram app are incorporated into Nicegram as fast as possible. Just be patient and
            wait.
          </SC.QuestionItemText>
        </SC.QuestionItem>{' '}
      </SC.QuestionsList>
    </SC.Wrapper>
  );
}

export { FaqBlock };

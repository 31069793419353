'use client';

import styled from 'styled-components';

export const Root = styled.div`
  padding: 20px;
  border-radius: 20px;
  background: var(--background);
  overflow: hidden;
  height: 100%;
`;

export const OuterRoot = styled.div`
  height: 100%;
  border-radius: 20px;
  padding: 2px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
`;

export const Card = styled.div`
  border-radius: 15px;
  border: 1px solid #38383a;
  padding: 20px;
`;
